
import { NButton, NButtonType } from '@/uikit';
import { fromFs } from '@/uikit/NAttachments';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FileAttachButton',
  components: { NButton },
  props: {
    accept: { type: String, default: 'image/*' },
    icon: { type: String, default: 'upload' },
    type: { type: String as PropType<NButtonType>, default: NButtonType.Secondary },
    i18n_label: { type: String, default: 'common.photo_upload' }
  },
  emits: ['attach'],
  methods: {
    async attach(): Promise<void> {
      const [file] = await fromFs(this.accept, false);
      this.$emit('attach', file);
    }
  }
});
