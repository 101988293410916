
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import ObjectItem from '@/pages/cards/content/ObjectItem.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import CameraShotAttach from '@/pages/users/uploader/CameraShotAttach.vue';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';
import { RouterModule } from '@/store/router';
import { aclModule } from '@/store/acl';
import { IModelAclResult } from '@/store/acl/types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

@Options({
  components: { ParticipantItem, SortDropdown, NButton, ObjectItem, DetectionDialog, CameraShotAttach }
})
export default class ItemsView extends Vue {
  @Prop({ type: Object, required: true })
  readonly module!: ListViewModel<any, any>;

  @Prop({ type: Array, required: false })
  readonly sortTypes?: any[] = [];

  @Prop({ type: Boolean, required: false })
  readonly uploadFeature: boolean = false;

  @Prop({ type: Boolean, required: false })
  readonly participants: boolean = false;

  @Prop({ type: Object, default: { add: true, view: true, delete: true, update: false } })
  readonly modelAcl: IModelAclResult = { add: true, view: true, delete: true, update: false };

  private participantsItems: EnrichedParticipant[] = [];

  get items() {
    return this.participants ? this.participantsItems : this.module.items;
  }

  @Watch('module')
  async moduleHandler() {
    await this.load();
  }

  async mounted() {
    await this.load();
  }

  @Watch('module.items')
  async enrichParticipants() {
    if (!this.participants) return;
    this.participantsItems = await enrichParticipants(this.module.items, this.participantsItems);
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (v?.page !== p.page) return;
    if (v?.limit !== p.limit) return;
    this.load();
  }

  async load() {
    const filter = this.module.filter.current;
    const hasEmptyClusterIn = filter.cluster_in && filter.cluster_in.length && filter.cluster_in[0] === 0;
    if (hasEmptyClusterIn) return;
    await this.module.get();
  }

  attachHandler(files: File[]) {
    const file = files[0];
    this.$emit('action', ItemsActionNames.Detect, file);
  }

  participantClickHandler(item: any) {
    RouterModule.navigateToParticipants(item);
  }
}
