import {
  BatchUploadService,
  BodyObjectRequest,
  CardsService,
  CarObjectRequest,
  DetectService,
  FaceObjectRequest,
  ObjectsService,
  Upload,
  UploadList
} from '@/api';
import { DetectResult, ObjectType } from '@/components/detection/types';
import { BatchUploaderFormItem } from '@/store/cards/types';
import { DataServiceRepository, viewModelRepository } from '@/api/common';
import { CardTypesMap, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { dataModule } from '@/store/data';
import { formatFileSize } from '@/common/filters';

export const defaultFormState: BatchUploaderFormItem = {
  filenameAsName: false,
  prefixName: '',
  suffixName: '',
  filenameAsComment: false,
  prefixComment: '',
  suffixComment: '',
  watch_lists: [1],
  photo_group: 'reject',
  parallel_upload: 5
};

const photoGroupAction: Record<any, any> = {
  ALL: 'all',
  BIGGEST: 'biggest',
  REJECT: 'reject'
};

export type BatchFileItem = {
  blob: File | null;
  src: string;
  fileName: string;
  size: string;
  cards: number[];
};
export type BatchStatistics = {
  statusCode?: string;
  errorCode?: string;
};

export type BatchFile = {
  file: BatchFileItem;
  statistics: BatchStatistics;
};

const area = (bbox: Record<string, number>) => (bbox.bottom - bbox.top) * (bbox.right - bbox.left);
const biggest = (objects: any) => objects.reduce((acc: any, el: any) => (area(el.bbox) > area(acc.bbox) ? el : acc));

export class BatchUploaderModule {
  detectItems: any = [];
  private batchUploaderFormItem: BatchUploaderFormItem = defaultFormState;
  private readonly objectType: string;
  private filesAsArray: any[] = [];
  private upload_list!: UploadList;
  private total_uploaded = 0;
  loading = false;
  paused = false;
  finished = false;

  constructor(objectType: string) {
    this.objectType = objectType;
  }

  get dataService() {
    return DataServiceRepository;
  }

  get ObjectsFaces() {
    return viewModelRepository.getObjectsFacesItemViewModel();
  }

  get detectionObjectType() {
    return ObjectsMultiToSingle[this.objectType];
  }

  get cardType() {
    return dataAssetsModule.getCardTypeByObjectType(this.objectType);
  }

  get currentUserLogin() {
    return dataModule.currentUserModule.item?.name;
  }

  get parallelRequestAmount() {
    return new Array(this.batchUploaderFormItem.parallel_upload).fill(0);
  }

  get nameForBatchUpload() {
    return this.currentUserLogin + '-' + new Date().getTime() * 1000 + Math.floor(Math.random() * 1000);
  }

  set formState(item: BatchUploaderFormItem) {
    this.batchUploaderFormItem = item;
  }

  get progress() {
    return (this.total_uploaded * 100) / this.detectItems.length;
  }

  get totalErrors() {
    return this.detectItems.filter((item: any) => !!item.statistics.errorCode).length;
  }

  get total() {
    return {
      uploaded: this.total_uploaded,
      errors: this.totalErrors,
      progress: this.progress
    };
  }

  addFiles(files: File[]) {
    this.finished = false;
    const resultFilesList = files.map((file: File) => {
      return {
        file: {
          blob: file,
          src: URL.createObjectURL(file),
          fileName: file!.name,
          size: formatFileSize(file!.size, true),
          cards: []
        },
        statistics: {
          statusCode: '',
          errorCode: ''
        }
      };
    });

    this.detectItems = resultFilesList;
    this.filesAsArray = [...resultFilesList];
  }

  async upload() {
    this.upload_list = await BatchUploadService.batchUploadCreate({ name: this.nameForBatchUpload });
    this.loading = true;
    Promise.all(this.parallelRequestAmount.map(() => this.startProcess())).finally(() => {
      this.loading = false;
      this.finished = true;
    });
  }

  // @ts-ignore
  async startProcess() {
    if (this.filesAsArray.length === 0 || this.paused) return Promise.resolve(true);
    const item: BatchFile = this.filesAsArray.shift();
    try {
      const detectItem = item.file && (await this.detect(item));
      const filteredDetectItem = this.checkGroupPhoto(detectItem);
      await this.createCardsWithObjects(filteredDetectItem, item);
    } catch (e) {
      item.statistics.errorCode = e.message;
    }
    return this.startProcess();
  }

  async createCardsWithObjects(detect: any, item: any) {
    for (const detectItem of detect) {
      const detectIndex: number = detect.indexOf(detectItem);
      const card = await this.createCard(item.file.fileName, detectIndex);

      try {
        await this.addObjectToCard({ card: card!.id, source_photo: item.file.blob, detect_id: detectItem.id });
        this.total_uploaded += 1;
        item.file.cards.push(card!.id);
        item.statistics.statusCode = 'success';
      } catch (e) {
        await this.deleteCard(card!.id);
      }
    }
  }

  async detect(item: BatchFile) {
    const file = item.file;
    let result = {
      objects: {}
    };
    let payload = { photo: file.blob, attributes: { [this.detectionObjectType]: {} } };
    try {
      result = (await DetectService.detectCreate(payload as any)) as DetectResult;
    } catch (e) {
      throw new Error('detection_error');
    }
    return Object.keys(result.objects).length !== 0 ? { ...result.objects } : null;
  }

  async createCard(fileName: string, detectIndex: number) {
    const requestBody = this.prepareFormData(fileName, detectIndex);
    switch (this.cardType) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansCreate(requestBody);
      case CardTypesMap.Cars:
        return CardsService.cardsCarsCreate(requestBody);
    }
  }

  async deleteCard(id: number) {
    switch (this.cardType) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansDestroy(id);
      case CardTypesMap.Cars:
        return CardsService.cardsCarsDestroy(id);
    }
  }

  async addObjectToCard(form: FaceObjectRequest | BodyObjectRequest | CarObjectRequest) {
    switch (this.detectionObjectType) {
      case ObjectType.Face:
        return ObjectsService.objectsFacesCreate(form);
      case ObjectType.Body:
        return ObjectsService.objectsBodiesCreate(form);
      case ObjectType.Car:
        return ObjectsService.objectsCarsCreate(form);
    }
  }

  private prepareFormData(fileName: string, detectIndex: number) {
    return {
      name: this.getName(fileName) + (detectIndex > 0 ? `_${detectIndex}` : ''),
      comment: this.getComment(fileName),
      watch_lists: this.batchUploaderFormItem.watch_lists,
      active: true,
      upload_list: this.upload_list.id
    };
  }

  private getPurifiedFileName(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  getComment(fileName: string) {
    return (
      this.batchUploaderFormItem.prefixComment +
      (this.batchUploaderFormItem.filenameAsComment ? this.getPurifiedFileName(fileName) : '') +
      this.batchUploaderFormItem.suffixComment
    );
  }

  getName(fileName: string) {
    return (
      this.batchUploaderFormItem.prefixName +
      (this.batchUploaderFormItem.filenameAsName ? this.getPurifiedFileName(fileName) : Math.round(Math.random() * 1e12).toString()) +
      this.batchUploaderFormItem.suffixName
    );
  }

  private checkGroupPhoto(detectionItem: any) {
    const { photo_group } = this.batchUploaderFormItem;
    const originalItems = detectionItem[this.detectionObjectType];
    const items = originalItems.filter(({ low_quality }: { low_quality: boolean }) => !low_quality);

    if (items.length === 0) {
      throw new Error('no_objects');
    } else if (photo_group === photoGroupAction.ALL || items.length === 1) {
      return items;
    } else if (photo_group === photoGroupAction.REJECT && items.length > 1) {
      throw new Error('detection_error');
    } else if (photo_group === photoGroupAction.BIGGEST) {
      return [biggest(items)];
    }
  }
}

export const batchUploaderModule = (type: string) => new BatchUploaderModule(type);
