
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import NTable, { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import { defineComponent } from 'vue';
import { arrayOf, func, object } from 'vue-types';
import { CommonPermissionGroup, PermissionBlockPredicate, PermissionGrantPredicate } from './types';

export default defineComponent({
  name: 'GroupCommonPermissions',
  components: { NTable },
  props: {
    groups: arrayOf(object<CommonPermissionGroup>()).isRequired,
    isBlocked: func<PermissionBlockPredicate>().isRequired,
    isGranted: func<PermissionGrantPredicate>().isRequired
  },
  emits: ['toggle'],
  computed: {
    columns(): ITableColumn[] {
      const columns: ITableColumn[] = [
        {
          header: {
            classes: ['bottom-border'],
            component: NTableColumnCell,
            props: { i18n_label: 'common.column_name' }
          },
          content: {
            path: 'target',
            component: SimpleText
          }
        },
        {
          width: '100px',
          header: {
            classes: ['align-center', 'bottom-border'],
            component: NTableColumnCell,
            props: { i18n_label: 'common.column_active' }
          },
          content: {
            classes: ['align-center'],
            component: NCheckbox,
            props: ({ permission }: CommonPermissionGroup) => ({
              disabled: this.isBlocked(permission),
              modelValue: this.isGranted(permission),
              'onUpdate:modelValue': () => this.$emit('toggle', permission)
            })
          }
        }
      ];
      return columns;
    }
  }
});
