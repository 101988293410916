import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vms-events__container" }
const _hoisted_2 = { class: "label-xs" }
const _hoisted_3 = { class: "vms-events__content" }
const _hoisted_4 = {
  key: 0,
  class: "interactions-banner"
}
const _hoisted_5 = {
  key: 1,
  class: "interactions-banner"
}
const _hoisted_6 = {
  key: 2,
  class: "interactions-banner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFilterDateRange = _resolveComponent("NFilterDateRange")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_NInfiniteScroll = _resolveComponent("NInfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NFilterDateRange, {
        size: "small",
        model: _ctx.module.filter.current
      }, null, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NTable, {
        columns: _ctx.columns,
        content: _ctx.module.items,
        class: "vms-events__table",
        onRowClick: _ctx.rowClickHandler
      }, null, 8, ["columns", "content", "onRowClick"]),
      (_ctx.module.loaded && !_ctx.module.items.length && !_ctx.module.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('common.no_items', 'f')), 1))
        : _createCommentVNode("", true),
      (_ctx.module.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('common.loading', 'f')), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_NInfiniteScroll, {
        filters: _ctx.module.filter.current,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
      }, null, 8, ["filters"]),
      (_ctx.module.appending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('common.loading', 'f')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}