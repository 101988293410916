import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LiveLabel = _resolveComponent("LiveLabel")!
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_EventInfoSidebarContent = _resolveComponent("EventInfoSidebarContent")!
  const _component_EpisodeEventsPage = _resolveComponent("EpisodeEventsPage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SidebarHeader, {
      "model-acl": _ctx.modelAcl,
      label: _ctx.headerLabel,
      changed: false,
      actions: _ctx.actions,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, _createSlots({ _: 2 }, [
      (_ctx.isLiveEpisode)
        ? {
            name: "extra-status",
            fn: _withCtx(() => [
              _createVNode(_component_LiveLabel)
            ])
          }
        : undefined
    ]), 1032, ["model-acl", "label", "actions"]),
    (_ctx.tabsItems)
      ? (_openBlock(), _createBlock(_component_NTabs, {
          key: 0,
          items: _ctx.tabsItems,
          modelValue: _ctx.tabName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tabName) = $event)),
          class: "multisidebar-content__tabs control-m"
        }, null, 8, ["items", "modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.currentItem)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isEvents ? true : _ctx.tabName === _ctx.TabNames.Info)
              ? (_openBlock(), _createBlock(_component_EventInfoSidebarContent, {
                  key: 0,
                  item: _ctx.currentItem,
                  "event-view": _ctx.currentEventViewItem,
                  "card-type": _ctx.cardType,
                  "object-type": _ctx.objectType,
                  "model-acl": _ctx.modelAcl,
                  onAction: _cache[2] || (_cache[2] = (...args) => _ctx.actionHandler(...args))
                }, null, 8, ["item", "event-view", "card-type", "object-type", "model-acl"]))
              : _createCommentVNode("", true),
            (_ctx.tabName === _ctx.TabNames.Events)
              ? (_openBlock(), _createBlock(_component_EpisodeEventsPage, {
                  key: 1,
                  item: _ctx.currentItem,
                  type: _ctx.rawType,
                  onAction: _ctx.episodeEventActionHandler
                }, null, 8, ["item", "type", "onAction"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 64))
}