
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButton } from '@/uikit';
import { EventView } from '@/components/events/adapter';
import SimpleReaction from '@/components/common/SimpleReaction.vue';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';
import MatchedCardInfo from '@/pages/events/MatchedCardInfo.vue';
import AcknowledgeInfo from '@/components/common/AcknowledgeInfo.vue';
import Acknowledge from '@/components/common/Acknowledge.vue';
import CameraLabelsV2 from '@/components/common/CameraLabelsV2.vue';
import Attributes from '@/components/common/Attributes.vue';
import { WatchListItemMarkers } from '@/components/common/watch-list-types';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { getDefaultMetaFormFields, MetaFormFields } from '@/components/common/meta-form-fields';
import NForm from '@/uikit/forms/NForm.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { configModule } from '@/store/config';
import { EventOrEpisode } from '@/components/events/types';
import { IModelAclResult } from '@/store/acl/types';

@Options({
  name: 'EventInfoSidebarContent',
  components: {
    NThumbnail,
    NForm,
    AcknowledgeInfo,
    MatchedCardInfo,
    NButton,
    SimpleReaction,
    CameraLabelsV2,
    WatchListsGroup,
    Acknowledge,
    Attributes
  }
})
export default class EventInfoSidebarContent extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EventOrEpisode;

  @Prop({ type: Object, required: true })
  readonly eventView!: EventView;

  @Prop({ type: String, required: true })
  readonly objectType!: string;

  @Prop({ type: String, required: true })
  readonly cardType!: string;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: IModelAclResult;

  get hasAttributes(): boolean {
    return !!Object.keys(this.eventView.features || this.eventView.faces_features || this.eventView.bodies_features || this.eventView.cars_features || {})
      .length;
  }

  get attributesItems() {
    return {
      faces: { display: !!this.eventView.faces_features, features: this.eventView.faces_features },
      bodies: { display: !!this.eventView.bodies_features, features: this.eventView.bodies_features },
      cars: { display: !!this.eventView.cars_features, features: this.eventView.cars_features }
    };
  }

  get marker() {
    return WatchListItemMarkers.Circle;
  }

  get infoFields() {
    return getDefaultMetaFormFields([MetaFormFields.Id, MetaFormFields.Created]);
  }

  get hasPlay() {
    return configModule.config.vms?.enabled;
  }

  showFullscreen() {
    this.$emit('action', ItemsActionNames.ShowFullScreen, this.eventView);
  }

  handleCameraAction(action: ItemsActionName, payload: any) {
    this.$emit('action', action, payload);
  }
}
