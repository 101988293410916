
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Counter } from '@/api';
import CounterROI from './CounterROI.vue';
import CounterROIModal from '@/pages/counters/CounterROIModal.vue';

@Options({
  name: 'CounterROIList',
  components: { CounterROIModal, CounterROI }
})
export default class CounterROIList extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Counter;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  modalEnabled = false;
  cameraStartIndex: number | null = null;

  showModal(cameraStartIndex: number) {
    this.modalEnabled = true;
    this.cameraStartIndex = cameraStartIndex;
  }
}
