
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ObjectItem from '@/pages/cards/content/ObjectItem.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import CameraShotAttach from '@/pages/users/uploader/CameraShotAttach.vue';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import { CaseCardsData, loadDataModule } from '@/store/data/LoadDataModule';
import { HumanCard } from '@/api';
import { CardTypesMap, DisplayTypesMap } from '@/store/application/data.assets';
import CardItem2 from '@/components/cards/deprecated/CardItem2.vue';
import CardItem from '@/components/cards/deprecated/CardItem.vue';
import ExtendedCaseDescription from '@/pages/cases/ExtendedCaseDescription.vue';

@Options({
  components: { ExtendedCaseDescription, CardItem2, CardItem, ParticipantItem, SortDropdown, NButton, ObjectItem, DetectionDialog, CameraShotAttach }
})
export default class CaseCardsView extends Vue {
  @Prop({ type: Array, required: false })
  readonly sortTypes?: any[] = [];

  @Prop({ type: Number, required: true })
  readonly cardId!: number;

  private caseCardsData: CaseCardsData | null = null;
  private loading: boolean = false;

  get cardType(): string {
    return CardTypesMap.Humans;
  }

  get displayType(): string {
    return DisplayTypesMap.Short;
  }

  @Watch('cardId')
  cardChangeHandler() {
    this.load();
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.caseCardsData = null;
    try {
      this.loading = true;
      this.caseCardsData = await loadDataModule.getLinkedCaseHumanCardsByCardID(this.cardId);
    } catch (e) {
      console.warn('case cards load error', e);
    } finally {
      this.loading = false;
    }
  }

  getCaseCards(id: number): HumanCard[] {
    const participants = this.caseCardsData?.caseParticipants?.filter((v) => v.case === id);
    if (!participants) return [];
    const allCardsArray = participants.reduce<number[]>((m, v) => {
      if (Array.isArray(v.related_human_cards)) m = m.concat(v.related_human_cards);
      return m;
    }, []);
    const cardsArray = Array.from(new Set(allCardsArray));
    return cardsArray
      .map((v) => this.caseCardsData?.caseCards.find((card) => card.id === v))
      .filter((v) => !!v)
      .filter((v) => v!.id != this.cardId) as HumanCard[];
  }

  actionHandler(name: string, card: HumanCard) {
    this.$emit('action', name, { item: card });
  }
}
