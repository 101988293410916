import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "external-vms-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidebarHeader, {
      actions: _ctx.headerActions,
      changed: _ctx.module.hasChanges,
      label: _ctx.currentItem.verbose_name || _ctx.currentItem.name,
      "model-acl": _ctx.modelAcl,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem)),
      class: "external-vms-sidebar__header"
    }, null, 8, ["actions", "changed", "label", "model-acl"]),
    (_ctx.info)
      ? (_openBlock(), _createBlock(_component_NTabs, {
          key: 0,
          items: _ctx.tabItems,
          modelValue: _ctx.tabName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tabName) = $event)),
          class: "control-m external-vms-sidebar__tabs"
        }, null, 8, ["items", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.info)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mainComponent), {
          key: 1,
          config: _ctx.currentItemConfig,
          info: _ctx.info,
          item: _ctx.currentItem,
          class: "external-vms-sidebar__content"
        }, null, 8, ["config", "info", "item"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MultisidebarSaveActions, {
      "sidebar-item": _ctx.sidebarItem,
      "model-acl": _ctx.modelAcl
    }, null, 8, ["sidebar-item", "model-acl"])
  ]))
}