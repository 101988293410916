import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "case-cards-view" }
const _hoisted_2 = {
  key: 0,
  class: "case-cards-view__body"
}
const _hoisted_3 = { class: "case-cards-view__case" }
const _hoisted_4 = { class: "case-cards-view__cards" }
const _hoisted_5 = {
  key: 1,
  class: "case-cards-view__empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExtendedCaseDescription = _resolveComponent("ExtendedCaseDescription")!
  const _component_CardItem = _resolveComponent("CardItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.caseCardsData?.cases.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseCardsData?.cases, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.id
            }, [
              (_ctx.getCaseCards(item.id)?.length)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ExtendedCaseDescription, { item: item }, null, 8, ["item"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCaseCards(item.id), (cardItem) => {
                        return (_openBlock(), _createBlock(_component_CardItem, {
                          key: cardItem.id,
                          type: _ctx.cardType,
                          item: cardItem,
                          selectable: false,
                          displayType: _ctx.displayType,
                          "show-fullframe": true,
                          onAction: (action) => _ctx.actionHandler(action, cardItem)
                        }, null, 8, ["type", "item", "displayType", "onAction"]))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('common.no_data', 'f')), 1))
        : _createCommentVNode("", true)
  ]))
}