
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import Confidence from '@/components/common/Confidence.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import EventInfoSidebarContent from '@/pages/events/EventInfoSidebarContent.vue';
import EpisodeEventsPage from '@/pages/events/EpisodeEventsPage.vue';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { PageType } from '@/store/application/page.definitions';
import { ItemViewModel } from '@/definitions/view-models';
import { eventEpisodeAdapter, EventView } from '@/components/events/adapter';
import { CardTypesMap, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { EventDetails } from '@/uikit/thumbnail';
import { actionHandler } from '@/store/data/ActionHandler';
import {
  BodyEvent,
  BodyObjectRequest,
  CardsService,
  CarEpisode,
  CarEvent,
  CarObjectRequest,
  FaceEvent,
  FaceObjectRequest,
  HumanEpisode,
  ObjectsService
} from '@/api';
import LiveLabel from '@/components/common/LiveLabel.vue';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { ObjectType } from '@/components/detection/types';
import { IModelAclResult } from '@/store/acl/types';
import { websocketModule } from '@/store/ws/websocket.module';

const EpisodeTabNames = {
  Info: 'info',
  Events: 'events'
};

const EpisodesTabs = Object.values(EpisodeTabNames);

type Item = FaceEvent | BodyEvent | CarEvent | HumanEpisode | CarEpisode;

@Options({
  name: 'EventContentItem',
  components: { EpisodeEventsPage, EventInfoSidebarContent, NTabs, Confidence, SidebarHeader, LiveLabel }
})
export default class EventContentItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Item>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: IModelAclResult;

  tabName = '';

  get headerLabel() {
    if (this.isEvents) {
      return this.$t(this.currentEventViewItem?.matched_card ? 'events.matched_event' : 'events.unmatched_event', 'f');
    } else return this.$t(this.currentEventViewItem?.matched_card ? 'events.matched_episode' : 'events.unmatched_episode', 'f');
  }

  get rawType() {
    return this.sidebarItem.type.split('_')[1];
  }

  get rawItem() {
    return this.sidebarItem.model.item;
  }

  get objectType() {
    return this.isEvents ? this.rawType : dataAssetsModule.getObjectTypeByEpisode(this.rawItem as HumanEpisode | CarEpisode);
  }

  get cardType() {
    return this.isEvents ? dataAssetsModule.getCardTypeByObjectType(this.rawType) : dataAssetsModule.getCardTypeByEpisodeType(this.rawType);
  }

  get isEvents(): boolean {
    return this.pageType === PageType.events;
  }

  get pageType() {
    return this.sidebarItem.type.split('_')[0];
  }

  get isLiveEpisode() {
    return !this.isEvents && this.currentEventViewItem?.open;
  }

  get TabNames() {
    return EpisodeTabNames;
  }

  get tabsItems(): any[] | null {
    let result = null;
    switch (this.pageType) {
      case PageType.episodes:
        result = EpisodesTabs.map((v) => ({ name: v, label: this.$t(`common.${v}`, 'f') }));
        break;
    }
    return result;
  }

  get currentItem() {
    return this.module.item;
  }

  get module(): ItemViewModel<any> {
    return this.sidebarItem.model;
  }

  get currentEventViewItem(): EventView | null {
    return this.currentItem ? eventEpisodeAdapter(this.currentItem, this.objectType) : null;
  }

  get actions() {
    return this.isEvents
      ? [
          {
            name: ItemsActionNames.Search,
            i18n_label: 'common.search',
            hidden: true
          },
          {
            name: ItemsActionNames.CreateCard,
            i18n_label: 'common.create_card',
            hidden: true
          }
        ]
      : [];
  }

  get websocketModule() {
    return websocketModule;
  }

  episodeEventActionHandler(action: string, id: string | number, item: any) {
    switch (action) {
      case EventDetails.ShowInfo:
        actionHandler.run(ItemsActionNames.ShowItem, { type: `events_${this.objectType}`, rawItem: item || id });
        break;
    }
  }

  showPlayer(cameraId: number) {
    const timeFrom = new Date(this.module.item.created_date).getTime() / 1000;
    this.$videoPlayer.playArchive(cameraId, timeFrom - 3);
  }

  actionHandler(action: ItemsActionName, payload: any) {
    switch (action) {
      case ItemsActionNames.ShowPlayer:
        this.showPlayer(payload);
        break;

      case ItemsActionNames.Acknowledge:
        this.acknowledge(payload);
        break;

      case ItemsActionNames.NavigateToUser:
        this.navigateToUser(payload);
        break;

      case ItemsActionNames.CreateCard:
        this.createCardFromEvent(payload);
        break;

      default:
        actionHandler.run(action, payload);
    }
  }

  navigateToUser(id: number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Users, rawItem: id });
  }

  async acknowledge(payload: any): Promise<void> {
    const id = this.sidebarItem.model.item?.id;
    if (this.isEvents) {
      await this.sidebarItem.model.update({ id, acknowledged: payload.value } as Partial<Item>);
    } else {
      const { best_car_event, best_body_event, best_face_event } = this.sidebarItem.model.item as any;
      await this.sidebarItem.model.update({ id, acknowledged: payload.value } as Partial<Item>);
      Object.assign(this.sidebarItem.model.item, { best_car_event, best_body_event, best_face_event });
    }
  }

  getCardType(type: string) {
    const computedType = type.split('_')[1];
    return dataAssetsModule.getCardTypeByObjectType(computedType);
  }

  getDetectionObjectType(type: string) {
    const objectType = type.split('_')[1];
    return ObjectsMultiToSingle[objectType];
  }

  extractPlaceNumber(eventItem: any) {
    return eventItem.features.license_plate_number?.name;
  }

  async createCard(eventItem: any, type: string) {
    const cleanMatchedLists = eventItem.matched_lists.filter((i: number) => i > 0);
    const requestBody = {
      name: eventItem.id,
      watch_lists: cleanMatchedLists.length > 0 ? cleanMatchedLists : [1]
    };
    switch (type) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansCreate(requestBody);
      case CardTypesMap.Cars:
        this.extractPlaceNumber(eventItem) && Object.assign(requestBody, { license_plate_number: this.extractPlaceNumber(eventItem) });
        return CardsService.cardsCarsCreate(requestBody);
    }
  }

  async addObjectToCard(form: FaceObjectRequest | BodyObjectRequest | CarObjectRequest, detectionObjectType: string) {
    switch (detectionObjectType) {
      case ObjectType.Face:
        return ObjectsService.objectsFacesCreate(form);
      case ObjectType.Body:
        return ObjectsService.objectsBodiesCreate(form);
      case ObjectType.Car:
        return ObjectsService.objectsCarsCreate(form);
    }
  }

  async createCardFromEvent(payload: any) {
    const type = this.getCardType(payload.type);
    const eventItem = payload.model.item;

    try {
      const card = await this.createCard(eventItem, type);
      const detectionObjectType = this.getDetectionObjectType(payload.type);
      const request = {
        create_from: `${detectionObjectType}event:${eventItem.id}`,
        card: card!.id
      };
      await this.addObjectToCard(request, detectionObjectType);
      await actionHandler.run(ItemsActionNames.ShowItem, { type: `cards_${type}`, rawItem: card?.id });
    } catch (e) {
      throw new Error(e);
    }
  }
}
