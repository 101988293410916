
import { BatchUploadService, PaginatedUploadListList } from '@/api';
import BatchLoaderModalResult from '@/pages/cards/content/BatchLoaderModalResult.vue';
import { getBatchLoaderFormSchema } from '@/pages/cards/forms/batch-loader-form-schema.ts';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { batchUploaderModule, defaultFormState } from '@/store/cards/BatchUploaderModule';
import { BatchUploaderFormItem } from '@/store/cards/types.ts';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NLoadingProgress from '@/uikit/loading/NLoadingProgress.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { NAttachment, NAttachmentFileBlob, NAttachments } from '@/uikit/NAttachments';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    BatchLoaderModalResult,
    NAttachments,
    NButton,
    NButtonGroup,
    NForm,
    NLoadingProgress,
    NModalWindow
  }
})
export default class BatchLoaderModal extends Vue {
  private form: BatchUploaderFormItem = defaultFormState;
  private objectType: string = 'faces';
  private logs: PaginatedUploadListList = {};
  private files: File[] = [];

  get batchModule() {
    return reactive(batchUploaderModule(this.objectType));
  }
  get schema() {
    return getBatchLoaderFormSchema();
  }
  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }
  get resultItems() {
    return this.batchModule.detectItems;
  }
  get totalStatistics() {
    return this.batchModule.total;
  }
  get cardType() {
    return this.dataAssetsModule.getCardTypeByObjectType(this.objectType);
  }
  get loading() {
    return this.batchModule.loading;
  }
  get finished() {
    return this.batchModule.finished;
  }

  uploadHandler(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target && target.files) {
      this.batchModule.addFiles(Array.from(target.files));
      target.value = '';
    }
  }

  startingTimeout = false;
  dndResult: File[] = [];
  uploadDNDHandler(attachments: NAttachment[]) {
    this.dndResult.push(...attachments.map((attachment) => attachment.file as NAttachmentFileBlob));

    if (!this.startingTimeout) {
      this.startingTimeout = true;
      setTimeout(() => {
        this.files = this.dndResult;
        this.startingTimeout = false;
      }, 500);
    }
  }

  @Watch('files')
  handlerFiles(n: any, o: any) {
    this.batchModule.addFiles(n);
  }

  batchUpload() {
    this.batchModule.paused = false;
    this.batchModule.formState = this.form;
    this.batchModule.upload();
  }
  pauseUpload() {
    this.batchModule.paused = true;
    this.batchModule.loading = false;
  }
  async showLogs() {
    this.logs = await BatchUploadService.batchUploadList();
  }
}
