
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { WatchList, WatchListsService } from '@/api';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { getInformationLayout, getPermissionsLayout } from '@/pages/watch-lists/watch-list-form';
import { IMultitoolAction } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { loadAndSyncPermissions, PermissionsModelNamesMap } from '@/components/permissions/permission-helpers';

const Tabs = {
  Information: 'information',
  Permissions: 'permissions'
};

@Options({
  name: 'WatchListsSidebar',
  components: { NTabs, NForm, MultisidebarSaveActions, SidebarHeader }
})
export default class WatchListsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<WatchList>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, required: true })
  readonly itemAcl!: IItemAclResult;

  activeTab = Tabs.Information;

  get isFormDisabled() {
    return !(this.sidebarItem.model.isNew ? this.modelAcl.add : this.itemAcl.update);
  }

  get model() {
    return this.sidebarItem.model;
  }

  get tabItems() {
    const info = {
      name: Tabs.Information,
      label: this.$t(`common.${Tabs.Information}`, 'f')
    };
    const permissions = {
      name: Tabs.Permissions,
      label: this.$t(`common.${Tabs.Permissions}`, 'f')
    };

    return this.model.isNew ? [info] : [info, permissions];
  }

  get itemForm() {
    let activeTab = this.activeTab;
    let currentTypedItem = this.model?.item;
    if (currentTypedItem) {
      return activeTab === Tabs.Permissions ? getPermissionsLayout() : getInformationLayout(this.model.isNew);
    }
    return [];
  }

  get headerActions(): IMultitoolAction[] {
    return actionsModule
      .getItemActions(this.modelAcl, this.itemAcl, {
        hasChanges: this.model?.hasChanges,
        currentActive: this.model.item?.active,
        hasActive: this.model.item?.id !== -1,
        hasDelete: this.model.item?.id !== -1,
        hasDeleteCard: true
      })
      .map(actionsModule.computeActionByName);
  }

  @Watch('model.item.id')
  @Watch('activeTab', { immediate: true })
  async loadPermissionsOnPermissionTab() {
    if (this.activeTab === Tabs.Permissions) {
      await loadAndSyncPermissions(this.sidebarItem.model, PermissionsModelNamesMap.WatchLists);
    }
  }

  validateForm(): boolean {
    const result = this.$refs.form ? this.$refs.form.validate() : true;
    if (!result) {
      this.$refs.form.displayErrors();
    }
    return result;
  }

  handleAction(actionName: ItemsActionName) {
    actionHandler.run(actionName, this.sidebarItem);
  }

  created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
