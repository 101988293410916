
import { Options, Vue } from 'vue-class-component';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { Prop } from 'vue-property-decorator';
import { ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import { UserFaceUpdate } from '@/api';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { UserFaceRequestWithThumbnail } from '@/pages/users/types';
import { IThumbnailAction } from '@/uikit/thumbnail';

@Options({
  components: { NThumbnail }
})
export default class UserFace extends Vue {
  @Prop({ type: Object, required: true })
  readonly face!: UserFaceUpdate | UserFaceRequestWithThumbnail;

  @Prop({ type: Boolean, default: false })
  readonly isCover!: false;

  private defaultAction = ItemsActionNames.ShowFullScreen;

  get actions() {
    let actions: IThumbnailAction[] = [
      { icon: 'trash', name: ItemsActionNames.Delete, position: ThumbnailPositions.TopRight },
      { icon: 'eye-open', name: ItemsActionNames.ShowFullScreen, position: ThumbnailPositions.BottomLeft }
    ];

    if (!this.isCover) {
      actions.push({ icon: 'main-picture', name: ItemsActionNames.SetCover, position: ThumbnailPositions.BottomRight });
    }

    return actions;
  }
}
