import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import { FormValidatorNames, IFormLayout } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';

import SimpleText from '@/uikit/simple/SimpleText.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import { getDefaultMetaFormFields } from '@/components/common/meta-form-fields';
import NSwitch from '@/uikit/switch/NSwitch.vue';

export const dataSourceGeneralExternalDetector: IFormLayout = [
  {
    path: 'screenshot',
    classes: 'label-m n-form-w-12 n-form-pad-10',
    component: CameraScreenshot,
    props: { width: 520, displayWidth: '520px', hasPlay: false }
  },
  [
    {
      path: 'name',
      classes: 'n-form-w-3 n-form-pad-10',
      i18n_label: 'ds.external_detector_name',
      component: NInput,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      path: 'group',
      classes: 'n-form-w-3 n-form-pad-10',
      i18n_label: 'ds.external_detector_camera_group',
      component: CameraGroupName
    }
  ],
  {
    path: 'comment',
    classes: 'n-form-w-6 n-form-pad-10',
    i18n_label: 'ds.description',
    component: NInput
  },
  {
    classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
    component: NSwitch,
    path: 'stream_settings.enable_liveness',
    props: { i18n_label: 'ds.enable_liveness' }
  },

  {
    path: 'external_detector_token',
    classes: 'n-form-w-6 n-form-pad-10',
    i18n_label: 'ds.external_detector_token',
    component: SimpleText,
    props: {
      copy: true
    }
  },
  ...getDefaultMetaFormFields()
];
