import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_CounterRecords = _resolveComponent("CounterRecords")!
  const _component_CounterForm = _resolveComponent("CounterForm")!
  const _component_CounterROIList = _resolveComponent("CounterROIList")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SidebarHeader, {
      label: _ctx.currentItem.name,
      "sub-label": _ctx.currentItem.name,
      inactivated: !_ctx.currentItem.active,
      changed: _ctx.moduleItem.hasChanges,
      "i18n-inactivated": "common.user_inactive",
      actions: _ctx.headerActions,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, null, 8, ["label", "sub-label", "inactivated", "changed", "actions"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabItems,
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    (_ctx.activeTab === 'records')
      ? (_openBlock(), _createBlock(_component_CounterRecords, {
          key: 0,
          counterId: _ctx.currentItem.id
        }, null, 8, ["counterId"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 'information')
      ? (_openBlock(), _createBlock(_component_CounterForm, {
          key: 1,
          ref: "form",
          model: _ctx.currentItem,
          disabled: _ctx.disabled
        }, null, 8, ["model", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.activeTab === 'roi')
      ? (_openBlock(), _createBlock(_component_CounterROIList, {
          key: 2,
          model: _ctx.currentItem,
          disabled: _ctx.disabled
        }, null, 8, ["model", "disabled"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MultisidebarSaveActions, {
      "sidebar-item": _ctx.sidebarItem,
      "model-acl": _ctx.modelAcl
    }, null, 8, ["sidebar-item", "model-acl"])
  ], 64))
}