<template>
  <div class="progress">
    <div class="progress__inner" :style="{ width: `${percent}%` }"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NLoadingProgress',
  props: {
    percent: {
      type: Number,
      default: 0
    }
  }
});
</script>

<style lang="stylus">
.progress {
  position: relative;
  border-radius: 5px;
  height: 8px;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-primary-750);
  &__inner {
    background-color: var(--color-positive);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width .3s;
  }
}
</style>
