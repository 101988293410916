
import { Options, Vue } from 'vue-class-component';
import { BatchFile } from '@/store/cards/BatchUploaderModule';
import { RouterModule } from '@/store/router';
import NButton from '@/uikit/buttons/NButton.vue';
import { Prop } from 'vue-property-decorator';

@Options({
  components: { NButton }
})
export default class BatchLoaderModalResult extends Vue {
  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: String, required: true })
  cardType!: string;

  getStatusBadge(item: BatchFile) {
    console.log('getStatusBadge item', item.statistics.errorCode);
    if (item.statistics.errorCode === 'no_objects') {
      return this.$t('cards.no_objects', 'f');
    }
    if (item.statistics.errorCode === 'detection_error') {
      return this.$t('cards.detection_error', 'f');
    }
    if (item.statistics.statusCode === 'success') {
      return this.$t('cards.success', 'f');
    }
    return '';
  }

  getResultCardTitle(cardId: number) {
    return `${this.$t('common.card', 'f')}: ${cardId}`;
  }

  goToCard(cardId: number) {
    RouterModule.navigateToCard(cardId, this.cardType);
    this.$emit('move-to-card');
  }
}
