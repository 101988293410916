import { IFormLayout } from '@/uikit/forms/NForm.vue';
import TestSplitter from '@/uikit/simple/TestSplitter.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import VideoProcessingPlayer from '@/components/data-source/VideoProcessingPlayer.vue';
import { Camera, VideoArchive } from '@/api';
import { dataSourceVideosModule } from '@/store/videos';
import { get } from 'lodash';
import { languageModule } from '@/store/languages';

export function getDataSourceInfo(isVideoFile = false, isPlayEnabled: boolean = false) {
  const screenshotField = {
    path: 'screenshot',
    classes: 'label-m n-form-w-12 n-form-pad-10',
    component: CameraScreenshot,
    props: { width: 520, displayWidth: '520px', hasPlay: isPlayEnabled }
  };


  const commonLayout: IFormLayout = [
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: TestSplitter,
      props: { i18n_label: 'ds.info' }
    },
    {
      path: 'id',
      classes: 'n-form-w-6 n-form-horizontal n-form-pad-10',
      i18n_label: 'common.id',
      component: SimpleText
    },
    {
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'common.status',
      component: SimpleText,
      props: (item: VideoArchive | Camera) => {
        return { modelValue: isVideoFile ? getStatusLabelValue(item as any) : item?.health_status?.status };
      }
    },
    {
      path: 'health_status.statistic.processed_duration',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.process_duration',
      component: SimpleText
    },
    {
      path: 'health_status.statistic.frames_dropped',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.frames_dropped',
      component: SimpleText
    },
    {
      path: 'health_status.statistic.job_starts',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.job_starts',
      component: SimpleText,
    },
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: TestSplitter,
      props: { i18n_label: 'ds.objects_statistics' }
    },
    {
      path: 'health_status.statistic.objects.face.posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.faces_posted',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.face.not_posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.faces_not_posted',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.face.failed',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.faces_failed',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.body.posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.bodies_posted',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.body.not_posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.bodies_not_posted',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.body.failed',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.bodies_failed',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.car.posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.cars_posted',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.car.not_posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.cars_not_posted',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.car.failed',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.cars_failed',
      component: SimpleText,
      props: {
        defaultText: '-'
      }
    }
  ];

  return [isVideoFile ? videoPlayerField : screenshotField, ...commonLayout];
}

function getStatusLabelValue(item: any): string {
  let result: any = dataSourceVideosModule.getStatusInfo(get(item, 'health_status.status'), item.progress, item.__upload_progress, item.finished);
  if (result.i18n_label) result.label = languageModule.getTranslatedToken(result.i18n_label, 'f');
  return result.label;
}

const videoPlayerField = {
  path: 'screenshot',
  classes: 'label-m n-form-w-12 n-form-pad-10',
  component: VideoProcessingPlayer,
  props: (item: VideoArchive) => ({ item })
};

