import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "user-photos" }
const _hoisted_2 = {
  key: 0,
  class: "control-m"
}
const _hoisted_3 = {
  key: 1,
  class: "control-m"
}
const _hoisted_4 = {
  key: 0,
  class: "user-photos__items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileAttachButton = _resolveComponent("FileAttachButton")!
  const _component_CameraShotAttach = _resolveComponent("CameraShotAttach")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_UserFace = _resolveComponent("UserFace")!
  const _component_NAttachments = _resolveComponent("NAttachments")!
  const _component_DetectionDialog = _resolveComponent("DetectionDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NBaseBar, { class: "user-photos__bar" }, {
      start: _withCtx(() => [
        (_ctx.items.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.items.length) + " " + _toDisplayString(_ctx.$t('common.total')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('common.no_photos')), 1))
      ]),
      end: _withCtx(() => [
        _createVNode(_component_FileAttachButton, { onAttach: _ctx.handleAttach }, null, 8, ["onAttach"]),
        _createVNode(_component_CameraShotAttach, { onAttach: _ctx.handleAttach }, null, 8, ["onAttach"])
      ]),
      _: 1
    }),
    (_ctx.items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (userFace, i) => {
            return (_openBlock(), _createBlock(_component_UserFace, {
              "is-cover": _ctx.getIsCover(userFace),
              face: userFace,
              key: i,
              onAction: (v) => _ctx.handleAction(v, userFace)
            }, null, 8, ["is-cover", "face", "onAction"]))
          }), 128))
        ]))
      : (!_ctx.module.loading)
        ? (_openBlock(), _createBlock(_component_NAttachments, {
            key: 1,
            onAttach: _ctx.handleDndAttach,
            accept: "image/*",
            class: "user-photos__dnd",
            "disallow-attach": "",
            drag: "",
            "hide-amount": ""
          }, {
            droparea: _withCtx(() => [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.drag_and_drop_files')), 1),
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createVNode(_component_FileAttachButton, {
                  onAttach: _ctx.handleAttach,
                  type: "link",
                  icon: "",
                  i18n_label: "common.select_file"
                }, null, 8, ["onAttach"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('common.or')) + " ", 1),
                _createVNode(_component_CameraShotAttach, {
                  onAttach: _ctx.handleAttach,
                  type: "link",
                  icon: "",
                  i18n_label: "common.uploader_activate_webcam"
                }, null, 8, ["onAttach"])
              ])
            ]),
            _: 1
          }, 8, ["onAttach"]))
        : _createCommentVNode("", true),
    (_ctx.file)
      ? (_openBlock(), _createBlock(_component_DetectionDialog, {
          key: 2,
          "image-file": _ctx.file,
          "thumbnail-scale": 1.8,
          onApply: _ctx.applyDetection,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.file = null))
        }, null, 8, ["image-file", "thumbnail-scale", "onApply"]))
      : _createCommentVNode("", true)
  ]))
}