import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SidebarHeader, {
      label: _ctx.model.item.name,
      inactivated: !_ctx.model.item.active,
      changed: _ctx.model.hasChanges,
      "current-item-acl": _ctx.itemAcl,
      actions: _ctx.headerActions,
      "i18n-inactivated": "common.watch_list_inactive",
      onAction: _ctx.handleAction
    }, null, 8, ["label", "inactivated", "changed", "current-item-acl", "actions", "onAction"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabItems,
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_NForm, {
        ref: "form",
        layout: _ctx.itemForm,
        model: _ctx.model.item,
        key: `${_ctx.model.item.id}-${_ctx.activeTab}`,
        disabled: _ctx.isFormDisabled
      }, null, 8, ["layout", "model", "disabled"]))
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "item-acl": _ctx.itemAcl,
      "sidebar-item": _ctx.sidebarItem
    }, null, 8, ["model-acl", "item-acl", "sidebar-item"])
  ]))
}