
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import NForm from '@/uikit/forms/NForm.vue';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { IModelAclResult } from '@/store/acl/types';
import { getDataSourceInfo } from './forms/data-source-info';
import dataSourceAdvanced from './forms/data-source-advanced';
import dataSourceZones from './forms/data-source-zones';
import dataSourceMap from './forms/data-source-map';
import { getGeneralCameraLayout } from './forms/data-source-general-camera';
import { Camera, CamerasService, Detectors, VideoArchive } from '@/api';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { getConnectionTypeByItem } from './forms/utils';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import getAnalyticsLayout from './forms/data-source-analytics';
import { IMultitoolAction } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { dataSourceGeneralExternalDetector } from '@/pages/data-sources/forms/data-source-general-external-detector';
import { getDataSourceFileGeneralLayout } from '@/pages/data-sources/forms/data-source-general-file';
import { HealthStatus } from '@/definitions/app/health-status';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';

@Options({
  name: 'DataSourceSidebar',
  components: { MultisidebarSaveActions, NForm, NTabs, SidebarHeader }
})
export default class DataSourceSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Camera | VideoArchive>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: IModelAclResult;

  state: { activeTab: string } = {
    activeTab: 'info'
  };

  get pageType() {
    return this.sidebarItem.type;
  }

  get item() {
    return this.sidebarItem.model.item;
  }

  get disabled() {
    return !this.modelAcl.update;
  }

  get isExternalDetector() {
    return (this.item as Camera)?.external_detector;
  }

  get isVideoFile() {
    return this.pageType === MultisidebarItemTypes.Videos;
  }

  get isCamera() {
    return this.pageType === MultisidebarItemTypes.Cameras;
  }

  get isInactive() {
    return this.isVideoFile ? false : !this.item?.active;
  }

  get tabItems() {
    const result = [
      {
        name: 'info',
        label: this.$t('ds.info', 'f')
      },
      {
        name: 'general',
        label: this.$t('ds.general', 'f')
      },
      {
        name: 'advanced',
        label: this.$t('ds.advanced', 'f')
      },
      {
        name: 'zones',
        label: this.$t('ds.zones', 'f')
      },
      ...this.detectorTabs
    ];

    if (this.isCamera) {
      const mapItem = {
        name: 'map',
        label: this.$t('ds.map', 'f')
      };
      result.push(mapItem);
    }

    return result;
  }

  get detectorTabs() {
    return dataAssetsModule.availableObjects.map((object) => ({
      name: object,
      label: this.$t(`ds.${ObjectsSingleToMulti[object]}`, 'f'),
      disabled: !this.item?.stream_settings?.detectors?.[object as keyof Detectors]
    }));
  }

  get formLayout() {
    if (this.isExternalDetector) {
      return dataSourceGeneralExternalDetector;
    }

    switch (this.state.activeTab) {
      case 'info':
        return this.dataSourceInfo;
      case 'general':
        return this.dataSourceGeneral;
      case 'advanced':
        return dataSourceAdvanced;
      case 'zones':
        return dataSourceZones;
      case 'face':
        return getAnalyticsLayout('face', this.isCamera);
      case 'body':
        return getAnalyticsLayout('body', this.isCamera);
      case 'car':
        return getAnalyticsLayout('car', this.isCamera);
      case 'map':
        return dataSourceMap;
      default:
        return [];
    }
  }

  get connectionType() {
    return this.isCamera ? getConnectionTypeByItem(this.item as Camera) : '';
  }

  get isExternalVmsEnabled() {
    return !!configModule.config.external_vms?.enabled;
  }

  get isPlayEnabled() {
    return !this.isExternalDetector;
  }

  get dataSourceInfo() {
    return getDataSourceInfo(this.isVideoFile, this.isPlayEnabled);
  }

  get dataSourceGeneral() {
    switch (this.pageType) {
      case MultisidebarItemTypes.Cameras:
        return getGeneralCameraLayout(this.connectionType, this.isExternalVmsEnabled);
      case MultisidebarItemTypes.Videos:
        return getDataSourceFileGeneralLayout(this.sidebarItem.model.item, dataAssetsModule.availableObjects);
      default:
        return [];
    }
  }

  get headerActions(): IMultitoolAction[] {
    let menuActions = [];

    if (this.isCamera) {
      menuActions = actionsModule
        .getItemActions(this.modelAcl, null, {
          hasActive: true,
          currentActive: this.item?.active,
          hasRestart: !this.isExternalDetector,
          hasChanges: this.sidebarItem.model.hasChanges,
          hasDelete: true,
          hasCameraReset: !this.isExternalDetector
        })
        .map(actionsModule.computeActionByName);
    } else {
      menuActions = actionsModule
        .getItemActions(this.modelAcl, null, {
          isArchivable: true,
          hasProcess: (this.item as VideoArchive)?.health_status?.status !== HealthStatus.InProgress,
          hasStopProcess: (this.item as VideoArchive)?.health_status?.status !== HealthStatus.Disabled,
          hasChanges: this.sidebarItem.model.hasChanges,
          hasDelete: true,
          hasDeleteFile: !!(this.item as VideoArchive).file_size,
          hasVideoReset: true
        })
        .map(actionsModule.computeActionByName);
    }

    const headerActions = actionsModule
      .getItemActions(this.modelAcl, null, {
        hasShowPlayer: configModule.config.vms?.enabled && !this.isExternalDetector && !this.isVideoFile
      })
      .map(actionsModule.computeActionByName)
      .map(actionsModule.addIconToAction)
      .map(actionsModule.makeActionVisible);

    return [...menuActions, ...headerActions];
  }

  async actionHandler(v: ItemsActionName) {
    switch (v) {
      case ItemsActionNames.Restart:
        this.item && CamerasService.camerasRestartCreate(this.item.id);
        break;
      case ItemsActionNames.ShowPlayer:
        if (this.item) {
          if (this.pageType === MultisidebarItemTypes.Cameras) {
            this.$videoPlayer.playCamera(this.item.id);
          } else {
            this.$videoPlayer.playVideo(this.item.id);
          }
        }
        break;
      default:
        actionHandler.run(v, this.sidebarItem);
    }
  }

  validateForm() {
    const form: NForm | undefined = this.$refs.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }

  created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
