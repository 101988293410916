import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "counter-roi" }
const _hoisted_2 = { class: "counter-roi__actions" }
const _hoisted_3 = { class: "counter-roi__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraName = _resolveComponent("CameraName")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_PolygonDrawTool = _resolveComponent("PolygonDrawTool")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CameraName, {
        modelValue: _ctx.cameraId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cameraId) = $event)),
        idIn: _ctx.model.cameras,
        readonly: ""
      }, null, 8, ["modelValue", "idIn"]),
      _createVNode(_component_NCheckbox, {
        modelValue: _ctx.onlyRect,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.onlyRect) = $event)),
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('counters.rect')), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "disabled"]),
      _createVNode(_component_NButton, {
        onAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadCameraImage(true))),
        icon: "table-action-refresh",
        disabled: _ctx.loading
      }, null, 8, ["disabled"]),
      (_ctx.hasFullscreen)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            onAction: _ctx.dispatchShowModal,
            icon: "fullscreen"
          }, null, 8, ["onAction"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        onAction: _ctx.clearRect,
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('counters.clear')), 1)
        ]),
        _: 1
      }, 8, ["onAction", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "text",
            onAction: _ctx.loadCameraImage,
            icon: "file-broken",
            "icon-size": "60"
          }, null, 8, ["onAction"]))
        : (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 1 }))
          : (_ctx.hasCamera)
            ? (_openBlock(), _createBlock(_component_PolygonDrawTool, {
                key: 2,
                ref: "drawTool",
                onlyRect: _ctx.onlyRect,
                image: _ctx.imageDataUrl,
                points: _ctx.points,
                disabled: _ctx.disabled,
                onChange: _cache[3] || (_cache[3] = (v) => (_ctx.points = v))
              }, null, 8, ["onlyRect", "image", "points", "disabled"]))
            : _createCommentVNode("", true)
    ])
  ]))
}