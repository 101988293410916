
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import cameraGroupInformation from './camera-group-information';
import cameraGroupPermissions from './camera-group-permissions';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { CameraGroup } from '@/api';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { loadAndSyncPermissions, PermissionsModelNamesMap } from '@/components/permissions/permission-helpers';
import { IMultitoolAction } from '@/uikit';
import { actionsModule } from '@/store/data/ActionsModule';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import NFormBlocks from '@/uikit/forms/NFormBlocks.vue';
import NForm from '@/uikit/forms/NForm.vue';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import Permissions from '@/components/permissions/Permissions.vue';

@Options({
  name: 'CameraGroupsSidebar',
  components: { Permissions, MultisidebarSaveActions, NForm, NFormBlocks, NTabs, SidebarHeader }
})
export default class CameraGroupsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<CameraGroup>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, required: true })
  readonly itemAcl!: IItemAclResult;

  Tabs = {
    Information: 'information',
    Permissions: 'permissions'
  };

  activeTab = this.Tabs.Information;
  cameraGroupInformation = cameraGroupInformation;
  cameraGroupPermissions = cameraGroupPermissions;

  get isFormDisabled() {
    return !(this.sidebarItem.model.isNew ? this.modelAcl.add : this.itemAcl.update);
  }

  get model() {
    return this.sidebarItem.model;
  }

  get tabItems() {
    const info = {
      name: this.Tabs.Information,
      label: this.$t(`common.${this.Tabs.Information}`, 'f')
    };
    const permissions = {
      name: this.Tabs.Permissions,
      label: this.$t(`common.${this.Tabs.Permissions}`, 'f')
    };

    return this.model.isNew ? [info] : [info, permissions];
  }

  get headerActions(): IMultitoolAction[] {
    return actionsModule
      .getItemActions(this.modelAcl, this.itemAcl, {
        hasChanges: this.model?.hasChanges,
        hasActive: true,
        hasDelete: true,
        currentActive: this.model.item?.active
      })
      .map(actionsModule.computeActionByName);
  }

  @Watch('model.item.id')
  @Watch('activeTab', { immediate: true })
  async loadPermissionsOnPermissionTab() {
    if (this.activeTab === this.Tabs.Permissions) {
      await loadAndSyncPermissions(this.sidebarItem.model, PermissionsModelNamesMap.CameraGroups);
    }
  }

  handleAction(actionName: ItemsActionName) {
    actionHandler.run(actionName, this.sidebarItem);
  }

  validateForm() {
    const result = this.$refs.form ? this.$refs.form.validate() : true;
    if (!result) {
      this.$refs.form.displayErrors();
    }
    return result;
  }

  created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
