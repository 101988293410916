
import { defineComponent } from 'vue';
import NButton from '@/uikit/buttons/NButton.vue';

export default defineComponent({
  name: 'NTableButtonCell',
  components: { NButton },
  props: {
    modelValue: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  methods: {
    handleClick() {
      this.$emit('update:modelValue', this.modelValue);
    }
  }
});
