
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CardTypesMap, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { loadDataModule } from '@/store/data/LoadDataModule';
import ObjectItem from '@/pages/cards/content/ObjectItem.vue';
import { ListViewModel } from '@/definitions/view-models';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { BodyObjectUpdate, CarObjectUpdate, FaceObjectUpdate } from '@/api';
import { actionHandler } from '@/store/data/ActionHandler';

@Options({
  name: 'CardFormFirstObjects',
  components: { NThumbnail, ObjectItem },
  emits: ['action']
})
export default class CardFormFirstObjects extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: String, required: true })
  readonly cardType!: string;

  public objectModels: ListViewModel<any, any>[] = [];

  get items() {
    return this.objectModels.map((model) => model.items[0]);
  }

  get availableObjectsMap() {
    return dataAssetsModule.availableObjectsMap;
  }

  get isAllowedId() {
    return this.modelValue > 0;
  }

  @Watch('modelValue', { immediate: true })
  async initObjectModels() {
    this.objectModels = [];
    if (!this.isAllowedId) return;
    const objectTypes = this.cardType === CardTypesMap.Humans ? ['faces', 'bodies'] : ['cars'];

    for (let objectType of objectTypes) {
      if (ObjectsMultiToSingle[objectType] in this.availableObjectsMap) {
        const objectsVM = loadDataModule.getObjectsLVMByCardID({ id: this.modelValue, limit: 1, type: objectType, ordering: 'id', autoload: false });
        await objectsVM.get();
        this.objectModels.push(objectsVM);
      }
    }
  }

  showFullscreen(item: FaceObjectUpdate | BodyObjectUpdate | CarObjectUpdate) {
    actionHandler.run(ItemsActionNames.ShowFullScreen, item);
  }
}
