import { AccessPermissionGroup, CommonPermissionGroup } from '../types';

export const SystemAccessPermissionGroups: AccessPermissionGroup[] = [
  {
    add: 'ffsecurity.add_all_own_sessions',
    view: 'ffsecurity.view_all_own_sessions',
    change: 'ffsecurity.change_all_own_sessions',
    delete: 'ffsecurity.delete_all_own_sessions',
    target: 'all_own_sessions'
  },
  {
    add: 'ffsecurity.add_area',
    view: 'ffsecurity.view_area',
    change: 'ffsecurity.change_area',
    delete: 'ffsecurity.delete_area',
    target: 'area'
  },
  {
    add: 'ffsecurity.add_bodycluster',
    view: 'ffsecurity.view_bodycluster',
    change: 'ffsecurity.change_bodycluster',
    delete: 'ffsecurity.delete_bodycluster',
    target: 'bodycluster'
  },
  {
    add: 'ffsecurity.add_bodyevent',
    view: 'ffsecurity.view_bodyevent',
    change: 'ffsecurity.change_bodyevent',
    delete: 'ffsecurity.delete_bodyevent',
    target: 'bodyevent'
  },
  {
    add: 'ffsecurity.add_bodyobject',
    view: 'ffsecurity.view_bodyobject',
    change: 'ffsecurity.change_bodyobject',
    delete: 'ffsecurity.delete_bodyobject',
    target: 'bodyobject'
  },
  {
    add: 'ffsecurity.add_camera',
    view: 'ffsecurity.view_camera',
    change: 'ffsecurity.change_camera',
    delete: 'ffsecurity.delete_camera',
    target: 'camera'
  },
  {
    add: 'ffsecurity.add_cameragroup',
    view: 'ffsecurity.view_cameragroup',
    change: 'ffsecurity.change_cameragroup',
    delete: 'ffsecurity.delete_cameragroup',
    target: 'cameragroup'
  },
  {
    add: 'ffsecurity.add_carcard',
    view: 'ffsecurity.view_carcard',
    change: 'ffsecurity.change_carcard',
    delete: 'ffsecurity.delete_carcard',
    target: 'carcard'
  },
  {
    add: 'ffsecurity.add_carcluster',
    view: 'ffsecurity.view_carcluster',
    change: 'ffsecurity.change_carcluster',
    delete: 'ffsecurity.delete_carcluster',
    target: 'carcluster'
  },
  {
    add: 'ffsecurity.add_carepisode',
    view: 'ffsecurity.view_carepisode',
    change: 'ffsecurity.change_carepisode',
    delete: 'ffsecurity.delete_carepisode',
    target: 'carepisode'
  },
  {
    add: 'ffsecurity.add_carevent',
    view: 'ffsecurity.view_carevent',
    change: 'ffsecurity.change_carevent',
    delete: 'ffsecurity.delete_carevent',
    target: 'carevent'
  },
  {
    add: 'ffsecurity.add_carobject',
    view: 'ffsecurity.view_carobject',
    change: 'ffsecurity.change_carobject',
    delete: 'ffsecurity.delete_carobject',
    target: 'carobject'
  },
  {
    add: 'ffsecurity.add_case',
    view: 'ffsecurity.view_case',
    change: 'ffsecurity.change_case',
    delete: 'ffsecurity.delete_case',
    target: 'case'
  },
  {
    add: 'ffsecurity.add_counter',
    view: 'ffsecurity.view_counter',
    change: 'ffsecurity.change_counter',
    delete: 'ffsecurity.delete_counter',
    target: 'counter'
  },
  {
    add: 'ffsecurity.add_deviceblacklistrecord',
    view: 'ffsecurity.view_deviceblacklistrecord',
    change: 'ffsecurity.change_deviceblacklistrecord',
    delete: 'ffsecurity.delete_deviceblacklistrecord',
    target: 'deviceblacklistrecord'
  },
  {
    add: 'ffsecurity.add_externalvms',
    view: 'ffsecurity.view_externalvms',
    change: 'ffsecurity.change_externalvms',
    delete: 'ffsecurity.delete_externalvms',
    target: 'externalvms'
  },
  {
    add: 'ffsecurity.add_externalvmsevent',
    view: 'ffsecurity.view_externalvmsevent',
    change: 'ffsecurity.change_externalvmsevent',
    delete: 'ffsecurity.delete_externalvmsevent',
    target: 'externalvmsevent'
  },
  {
    add: 'ffsecurity.add_externalvmssendeventstatus',
    view: 'ffsecurity.view_externalvmssendeventstatus',
    change: 'ffsecurity.change_externalvmssendeventstatus',
    delete: 'ffsecurity.delete_externalvmssendeventstatus',
    target: 'externalvmssendeventstatus'
  },
  {
    add: 'ffsecurity.add_facecluster',
    view: 'ffsecurity.view_facecluster',
    change: 'ffsecurity.change_facecluster',
    delete: 'ffsecurity.delete_facecluster',
    target: 'facecluster'
  },
  {
    add: 'ffsecurity.add_faceevent',
    view: 'ffsecurity.view_faceevent',
    change: 'ffsecurity.change_faceevent',
    delete: 'ffsecurity.delete_faceevent',
    target: 'faceevent'
  },
  {
    add: 'ffsecurity.add_faceobject',
    view: 'ffsecurity.view_faceobject',
    change: 'ffsecurity.change_faceobject',
    delete: 'ffsecurity.delete_faceobject',
    target: 'faceobject'
  },
  {
    add: 'ffsecurity.add_humancard',
    view: 'ffsecurity.view_humancard',
    change: 'ffsecurity.change_humancard',
    delete: 'ffsecurity.delete_humancard',
    target: 'humancard'
  },
  {
    add: 'ffsecurity.add_humanepisode',
    view: 'ffsecurity.view_humanepisode',
    change: 'ffsecurity.change_humanepisode',
    delete: 'ffsecurity.delete_humanepisode',
    target: 'humanepisode'
  },
  {
    add: 'ffsecurity.add_metadictionary',
    view: 'ffsecurity.view_metadictionary',
    change: 'ffsecurity.change_metadictionary',
    delete: 'ffsecurity.delete_metadictionary',
    target: 'metadictionary'
  },
  {
    add: 'ffsecurity.add_notification',
    view: 'ffsecurity.view_notification',
    change: 'ffsecurity.change_notification',
    delete: 'ffsecurity.delete_notification',
    target: 'notification'
  },
  {
    add: 'ffsecurity.add_report',
    view: 'ffsecurity.view_report',
    change: 'ffsecurity.change_report',
    delete: 'ffsecurity.delete_report',
    target: 'report'
  },
  {
    add: 'ffsecurity.add_upload',
    view: 'ffsecurity.view_upload',
    change: 'ffsecurity.change_upload',
    delete: 'ffsecurity.delete_upload',
    target: 'upload'
  },
  {
    add: 'ffsecurity.add_uploadlist',
    view: 'ffsecurity.view_uploadlist',
    change: 'ffsecurity.change_uploadlist',
    delete: 'ffsecurity.delete_uploadlist',
    target: 'uploadlist'
  },
  {
    add: 'ffsecurity.add_user',
    view: 'ffsecurity.view_user',
    change: 'ffsecurity.change_user',
    delete: 'ffsecurity.delete_user',
    target: 'user'
  },
  {
    add: 'ffsecurity.add_videoarchive',
    view: 'ffsecurity.view_videoarchive',
    change: 'ffsecurity.change_videoarchive',
    delete: 'ffsecurity.delete_videoarchive',
    target: 'videoarchive'
  },
  {
    add: 'ffsecurity.add_watchlist',
    view: 'ffsecurity.view_watchlist',
    change: 'ffsecurity.change_watchlist',
    delete: 'ffsecurity.delete_watchlist',
    target: 'watchlist'
  },
  {
    add: 'ffsecurity.add_webhook',
    view: 'ffsecurity.view_webhook',
    change: 'ffsecurity.change_webhook',
    delete: 'ffsecurity.delete_webhook',
    target: 'webhook'
  },
  {
    add: 'auth.add_group',
    view: 'auth.view_group',
    change: 'auth.change_group',
    delete: 'auth.delete_group',
    target: 'group'
  }
];

export const SystemCommonPermissionGroups: CommonPermissionGroup[] = [
  {
    permission: 'ffsecurity.batchupload_cards',
    target: 'batchupload_cards'
  },
  {
    permission: 'ffsecurity.change_runtimesetting',
    target: 'change_runtimesetting'
  },
  {
    permission: 'ffsecurity.configure_ntls',
    target: 'configure_ntls'
  },
  {
    permission: 'ffsecurity.view_auditlog',
    target: 'view_auditlog'
  },
  {
    permission: 'ffsecurity.view_runtimesetting',
    target: 'view_runtimesetting'
  }
];
