import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SidebarHeader, {
      label: _ctx.item.name,
      actions: _ctx.headerActions,
      item: _ctx.item,
      inactivated: _ctx.isInactive,
      "i18n-inactivated-label": "ds.inactivated",
      onAction: _ctx.actionHandler
    }, null, 8, ["label", "actions", "item", "inactivated", "onAction"]),
    (!_ctx.isExternalDetector)
      ? (_openBlock(), _createBlock(_component_NTabs, {
          key: 0,
          items: _ctx.tabItems,
          modelValue: _ctx.state.activeTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.activeTab) = $event)),
          class: "multisidebar-content__tabs control-m"
        }, null, 8, ["items", "modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NForm, {
        ref: "form",
        layout: _ctx.formLayout,
        model: _ctx.item,
        state: _ctx.state,
        disabled: _ctx.disabled
      }, null, 8, ["layout", "model", "state", "disabled"])
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "sidebar-item": _ctx.sidebarItem
    }, null, 8, ["model-acl", "sidebar-item"])
  ]))
}