
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { FaceObjectUpdate } from '@/api';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import { IModelAclResult } from '@/store/acl/types';

@Options({
  name: 'ParticipantItemRow',
  components: { NButton, NThumbnail }
})
export default class ObjectItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: FaceObjectUpdate;

  @Prop({ type: Array })
  readonly actions?: any[];

  @Prop({ type: Object })
  readonly acl?: IModelAclResult;

  get computedActions() {
    const defaultActions = [{ icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }];
    if (this.acl?.delete) defaultActions.unshift({ icon: 'trash', name: EventDetails.Remove, position: ThumbnailPositions.BottomLeft });

    return this.actions || defaultActions;
  }
}
