
import { Options, Vue } from 'vue-class-component';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import NButton from '@/uikit/buttons/NButton.vue';
import MultisidebarActions from '@/components/multisidebar/MultisidebarActions.vue';
import { actionHandler } from '@/store/data/ActionHandler';
import { Prop } from 'vue-property-decorator';
import { MultisidebarCommonItem } from '@/store/multisidebar/types';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { DefaultItemAclResult, DefaultModelAclResult } from '@/store/acl';

@Options({
  name: 'MultisidebarSaveActions',
  components: { MultisidebarActions, NButton },
  emits: ['action']
})
export default class MultisidebarSaveActions extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarCommonItem;

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly itemAcl!: IItemAclResult;

  get saveAvailable() {
    return this.sidebarItem.model.isNew ? this.modelAcl.add : (this.itemAcl.update && this.modelAcl.update);
  }

  save() {
    actionHandler.run(ItemsActionNames.Save, this.sidebarItem);
  }

  discard() {
    actionHandler.run(ItemsActionNames.Reset, this.sidebarItem);
  }
}
