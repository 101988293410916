import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardMonitoringStatus = _resolveComponent("CardMonitoringStatus")!
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_ItemsView = _resolveComponent("ItemsView")!
  const _component_CaseCardsView = _resolveComponent("CaseCardsView")!
  const _component_DetectionDialog = _resolveComponent("DetectionDialog")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!
  const _component_CardsDuplicateDialog = _resolveComponent("CardsDuplicateDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SidebarHeader, {
      "model-acl": _ctx.modelAcl,
      label: _ctx.currentItem.name,
      inactivated: !_ctx.currentItem?.active,
      changed: _ctx.module.hasChanges,
      actions: _ctx.headerActions,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, _createSlots({ _: 2 }, [
      (_ctx.isPuppeteerEnabled && _ctx.currentItemMonitoringRecord)
        ? {
            name: "extra-status",
            fn: _withCtx(() => [
              _createVNode(_component_CardMonitoringStatus, { item: _ctx.currentItemMonitoringRecord }, null, 8, ["item"])
            ])
          }
        : undefined
    ]), 1032, ["model-acl", "label", "inactivated", "changed", "actions"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabsItems,
      modelValue: _ctx.tabName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tabName) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.tabName === _ctx.TabItemsMap.Info)
        ? (_openBlock(), _createBlock(_component_CardForm, {
            key: 0,
            ref: "cardFormInstance",
            item: _ctx.currentItem,
            "card-type": _ctx.cardType,
            disabled: _ctx.disabled
          }, null, 8, ["item", "card-type", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.isMetaTab)
        ? (_openBlock(), _createBlock(_component_NForm, {
            key: 1,
            ref: "cardFormInstance",
            model: _ctx.currentItem.meta,
            layout: _ctx.metaTabFormSchema,
            disabled: _ctx.disabled
          }, null, 8, ["model", "layout", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.itemsModule)
        ? (_openBlock(), _createBlock(_component_ItemsView, {
            key: _ctx.itemsModule.name,
            module: _ctx.itemsModule,
            "model-acl": _ctx.tabModelAcl,
            sortTypes: _ctx.itemsSortTypes,
            "upload-feature": _ctx.uploadFeature,
            participants: _ctx.itemsModule === _ctx.participantsVM,
            onAction: _cache[2] || (_cache[2] = (v, payload) => _ctx.itemActionHandler(v, payload))
          }, null, 8, ["module", "model-acl", "sortTypes", "upload-feature", "participants"]))
        : _createCommentVNode("", true),
      (_ctx.tabName === _ctx.TabItemsMap.CaseHumanCards)
        ? (_openBlock(), _createBlock(_component_CaseCardsView, {
            key: 3,
            "card-id": _ctx.currentItem.id,
            onAction: _ctx.actionHandler
          }, null, 8, ["card-id", "onAction"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        name: "file",
        type: "file",
        ref: "fileInput",
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.setFile && _ctx.setFile(...args))),
        class: "hide-element"
      }, null, 544),
      (_ctx.showDetectionDialog)
        ? (_openBlock(), _createBlock(_component_DetectionDialog, {
            key: 4,
            "image-file": _ctx.file,
            options: _ctx.objectType,
            onApply: _ctx.applyDetection,
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDetectionDialog = false))
          }, null, 8, ["image-file", "options", "onApply"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "sidebar-item": _ctx.sidebarItem
    }, null, 8, ["model-acl", "sidebar-item"]),
    (_ctx.showDuplicateDialog)
      ? (_openBlock(), _createBlock(_component_CardsDuplicateDialog, {
          key: 0,
          image: _ctx.cardsDuplicate.thumbnail,
          cards: _ctx.cardsDuplicate.cards,
          cardType: _ctx.cardType,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDuplicateDialog = false)),
          onAddToCard: _ctx.addPhotoToCard
        }, null, 8, ["image", "cards", "cardType", "onAddToCard"]))
      : _createCommentVNode("", true)
  ]))
}