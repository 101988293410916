import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "webhooks-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidebarHeader, {
      actions: _ctx.headerActions,
      changed: _ctx.module.hasChanges,
      label: _ctx.currentItem.name,
      "model-acl": _ctx.modelAcl,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem)),
      class: "external-vms-sidebar__header"
    }, null, 8, ["actions", "changed", "label", "model-acl"]),
    _createVNode(_component_NForm, {
      ref: "form",
      model: _ctx.currentItem,
      layout: _ctx.layout,
      disabled: _ctx.disabled
    }, null, 8, ["model", "layout", "disabled"]),
    _createVNode(_component_MultisidebarSaveActions, {
      "sidebar-item": _ctx.sidebarItem,
      "model-acl": _ctx.modelAcl
    }, null, 8, ["sidebar-item", "model-acl"])
  ]))
}